<template>
  <b-card>
    <h4 class="mb-0">
      Edit Pengajuan Absensi
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="employee_nip" rules="required">
              <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                <v-select id="employee_nip" v-model="dataStudent.employee_nip"
                  :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal Mulai" label-for="start_date">
              <validation-provider #default="{ errors }" name="start_date" rules="required">
                <b-form-input id="start_date" v-model="dataStudent.start_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Mulai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Selesai" label-for="end_date">
              <validation-provider #default="{ errors }" name="end_date" rules="required">
                <b-form-input id="end_date" v-model="dataStudent.end_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Selesai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Masuk" label-for="time_in">
              <validation-provider #default="{ errors }" name="time_in" rules="required">
                <b-form-input id="time_in" v-model="dataStudent.time_in" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Masuk" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Keluar" label-for="time_out">
              <validation-provider #default="{ errors }" name="time_out" rules="required">
                <b-form-input id="time_out" v-model="dataStudent.time_out" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Keluar" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-group label="Type Absent" label-for="type" :state="errors.length > 0 ? false : null">
                  <v-select id="type" v-model="dataStudent.type"
                    :reduce="(type) => type.real_id" placeholder="Pilih Type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listAbsentType" label="absent_type_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            
            <validation-provider #default="{ errors }" name="approved" rules="required">
                <b-form-group label="Status" label-for="approved" :state="errors.length > 0 ? false : null">
                  <v-select id="approved" v-model="dataStudent.approved"
                    :reduce="(approved) => approved.real_id" placeholder="Pilih Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listApprovalStatus" label="approval_status" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            <b-form-group label="Komentar" label-for="comment">
              <validation-provider #default="{ errors }" name="comment" rules="required">
                <b-form-input id="comment" v-model="dataStudent.comment" :state="errors.length > 0 ? false : null"
                  placeholder="Komentar" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">

          </b-col>

        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listApprovalStatus: [],
    required,
    listPegawai: [],
    listAbsentType:[],
    dataStudent: {
      employee_nip: '',
      start_date: '',
      end_date: '',
      time_in: '',
      time_out: '',
      approved: '',
      comment: '',
      type: '',
    },
  }),
  computed: {

  },
  created() {
    this.getStudentDetail()
    this.getpegawai()
    this.approvalstatus()
    this.absenttype()
  },
  methods: {
    async approvalstatus() {
      try {
        const response = await this.$http.get('/approvalabsentstatuses')
        this.listApprovalStatus = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async absenttype() {
      try {
        const response = await this.$http.get('/absenttypes')
        this.listAbsentType = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/employeeabsents/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.employee_nip = data.employee_nip
      this.dataStudent.start_date = data.start_date.substr(0, 10)
      this.dataStudent.end_date = data.end_date.substr(0, 10)
      this.dataStudent.time_in = data.time_in
      this.dataStudent.time_out = data.time_out
      this.dataStudent.approved = data.approved
      this.dataStudent.comment = data.comment
      this.dataStudent.type = data.comment
    },      
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/employeeabsents/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'pengajuan-absensi' })            
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })            
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
